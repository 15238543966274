<template>
  <div class="c-app">
    <transition name="slide">
      <div v-if="showSidebar" class="sidebar">
        <Sidebar @clicked="showSidebar = !showSidebar" />
      </div>
    </transition>
    <CWrapper>
      <header class="c-header c-header-light px-3 position-relative">
        <button
          class="c-header-toggler sidebar-toggler"
          type="button"
          @click="showSidebar = !showSidebar"
        >
          <svg
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0H18V2H0V0ZM0 7H18V9H0V7ZM0 14H18V16H0V14Z"
              fill="#003366"
            />
          </svg>
        </button>
        <div class="d-flex align-items-center position-welcome pl-1"></div>
        <a class="pointer py-3 brand-position" href="/dashboard"
          ><img :src="urlLogo" alt="logo" width="125"
        /></a>
      </header>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter class="d-none" />
    </CWrapper>
  </div>
</template>

<script>
import Sidebar from "./Sidebar";
import TheFooter from "./TheFooter";

import { decrypt } from "../utils/decrypt";

export default {
  name: "TheContainer",
  components: {
    Sidebar,
    TheFooter,
  },
  data() {
    return {
      showSidebar: false,
      urlLogo: "",
    };
  },
  watch: {
    "$route.params": {
      handler: function () {
        this.showSidebar = false;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted: async function () {
    document.addEventListener("click", (e) => {
      if (
        e.target.closest("#sidebar") === null &&
        e.target.closest(".sidebar-toggler") === null
      ) {
        this.showSidebar = false;
      }
    });

    this.logoUrl();
  },
  methods: {
    async logoUrl() {
      const encrypted = localStorage.getItem("ama");
      const decrypted = decrypt(encrypted);
      const user = JSON.parse(decrypted);
      this.urlLogo = user.cidade_logo;
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.2s;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
}

.sidebar {
  z-index: 9999;
}

.brand-position {
  position: absolute;
  right: 32px;
  margin-top: -10px;
}

.position-welcome {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #131e3b;
}

.c-header {
  box-shadow: 0px 8px 12px rgba(160, 168, 189, 0.24);
  border: 0;

  .c-header-toggler-icon {
    background-image: url("../assets/img/toggle.svg");
    height: 18px;
  }
}

@media (max-width: 700px) {
  .position-welcome {
    display: none;
  }
}
</style>
