<template>
<CFooter :fixed="false">
    <div>
        <strong>
            <span class="ml-1">Zeladoria Ambiental Digital - {{city}}</span>
        </strong>
    </div>
    <div class="mfs-auto">
        <strong>
            <a href="http://smartcitizen.com.br/" rel="noreferrer" target="_blank">SmartCitizen</a>
            <span class="ml-1">&copy; {{ new Date().getFullYear() }}</span>
        </strong>
    </div>
</CFooter>
</template>

<script>

export default {
    name: 'TheFooter',
    data() {
        return {
            city: ''
        }
    },
    mounted: async function () {
        this.$store.dispatch('global/setUser').then(() => {
            const user = this.$store.state.global.user
            this.city = user.usuario_cidade.slice(0, -3)
        })
    },
}
</script>
